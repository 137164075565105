import React, {useEffect, useState} from 'react';
import * as C from './styles'
import { Player } from './components/player';
import './App.css';
import { Musics } from './components/musics';
import { Sidebar } from './components/sidebar';
import { Menu } from './svgs/index';
import Axios from 'axios';
import firebase from './firebase';

function App() {
 React.useEffect(() => {
    const msg = firebase.messaging();
    msg.requestPermission().then(() => {
      return msg.getToken()
    }).then((data) => {
      console.log(data)
    })
 })
  const [id, setId] = useState('')
  const [isFull, setIsFull] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  const [genre, setGenre] = useState('')
  const [search, setSearch] = useState('')
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [isSidebar, setIsSidebar] = useState(false)
  const [MusicList, setMusicList] = useState([])
  const [Url, SetUrl] = useState('')

  useEffect(()=>{
    Axios.get('https://stream.youradio.co.za/api/nowplaying').then((response) => {
      setMusicList(response.data);
    })
  },[])
    
    useEffect(() => {
      window.addEventListener("resize", () => {
           setWindowWidth(window.innerWidth)
      })
    }, [])
  return (
    <div>
      <C.Container>
          <Sidebar 
            setGenre={setGenre}
            setIsSearch={setIsSearch}
            setIsFull={setIsFull}
            isSearch={isSearch}
            isSidebar={isSidebar}
            setIsSidebar={setIsSidebar}
          />

        <div className='top'>
          {isSearch ? 
            <>
            {windowWidth <= 820 ? 
            <button className='showSidebar' onClick={() => setIsSidebar(!isSidebar)}><Menu />
            </button> 
            : ''}

            <input 
            onChange={(e) => setSearch(e.target.value)} 
            autoFocus placeholder='Search' type="text" />
            <h1 className='searchH1'>Search for music name, author or genre </h1> 
            </> :
            <>
              {windowWidth <= 820 ? 
              <button className='showSidebar' onClick={() => setIsSidebar(!isSidebar)}><Menu />
              </button>
              : ''}
              <h1 className='title'>{isFull && windowWidth <= 820 ? '' : 'All Stations'}</h1>
            </>
          }
          <div className='divSongs'>
            <C.Music>
            {MusicList.map(music => (
              <Musics 
                key={music.station.id}
                img={music.now_playing.song.art}
                name={music.station.name}
                author={music.station.description}
                audio={music.station.listen_url}
                genre={music.station.name}
                url={music.station.url}
                setId={setId}
                musicId={music.station.id}
                id={music.station.id}
                setIsFull={setIsFull}
                isFull={isFull}
                genres={genre}
                isSearch={isSearch}
                search={search}
                windowWidth={windowWidth}
                
              />
              ))
            }
            </C.Music>
          </div>
        </div>
      </C.Container>
          <Player 
            id={id}
            setId={setId}
            setIsFull={setIsFull}
            isFull={isFull}
            windowWidth={windowWidth}
          /> 
    </div>
  );
}

export default App;
