import * as C from './styles';
import { useEffect, useState } from  'react';
import Axios from 'axios';
import { CustomerServiceFilled, SearchOutlined, PlayCircleTwoTone } from '@ant-design/icons';


type Props = {
    setGenre: (e: string) => void;
    setIsSearch:(e: boolean) => void;
    setIsFull:(e: boolean) => void;
    isSearch: boolean;
    isSidebar: boolean;
    setIsSidebar: (e: boolean) => void;  
}




export const Sidebar = ({ setGenre, setIsSearch, setIsFull, isSearch, isSidebar, setIsSidebar }: Props) => {
    const [SideComp, setSideComp] = useState([]);

useEffect(() => {
    Axios.get("https://stream.youradio.co.za/api/nowplaying").then((response) => {
        setSideComp(response.data);
        console.log(response.data)
    });
},[])

    return (
        <C.Container isSidebar={isSidebar}>   
            <div className='inicialSearch'>
                <img src="https://www.kratos.co.za/img/backgroundyou.png" alt="" />
                <div 
                onClick={() => (setGenre(''), setIsSearch(false), setIsFull(false), setIsSidebar(false))} 
                className='iniSearDivs'>
                    <p><CustomerServiceFilled 
                        style={{
                            fontSize: 30,
                            color: 'orange'
                        }}
                    /></p> Play Now!
                </div>

                <div 
                onClick={() => (setIsSearch(!isSearch), setIsSidebar(false))}className='iniSearDivs'>
                    <p><SearchOutlined 
                        style={{
                            fontSize: 32,
                            color: 'orange'
                        }}
                    /></p> Search
                </div>
            </div>
                <div className='genres'>
                    <h2>Live Stream</h2>
                    {SideComp.map(val => (
                    <div  onClick={() => (setGenre(val.station.name) ,setIsSidebar(false), setIsSearch(false))} key={val.id}>
                       <p key={val.id}><PlayCircleTwoTone 
                            twoToneColor="orange"
                            style={{
                                fontSize:30
                            }}
                            key={val.id}
                       /> </p>   {val.station.name}
                    </div>
                    ))}
                </div>
        </C.Container>
    )
} 