// import modules
import firebase from 'firebase';
import { initializeApp } from "firebase/app"
import { getMessaging } from "firebase/messaging";

// Import the functions you need from the SDKs you need
const firebaseConfig = {
  apiKey: "AIzaSyC7bblmCuOW9jqKIkpUhtYNoMSfxdQ3xoQ",
  authDomain: "push-youradio-test.firebaseapp.com",
  projectId: "push-youradio-test",
  storageBucket: "push-youradio-test.appspot.com",
  messagingSenderId: "751412685258",
  appId: "1:751412685258:web:c7d2859fd8bb9f01050733",
  measurementId: "G-XP9R45GB7C"
};

// initialize app
firebase.initializeApp(firebaseConfig)

// export module
export default firebase;