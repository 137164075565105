// default initialization of function
export default function swDev() 
{

    // VAPID Key generation
    function determineAppServerKey() {
        var vapidPublicKey = 'BGR_dqk4oTW5Ip5nylxsOGt2EyW5hHwKsWi19NyHU75PMgKxrpuR-OvX_4th9NvEgihjytDTKtYbkHQxFcU1_Eo';
        return urlBase64ToUint8Array(vapidPublicKey);
    }

    // key formating function
    function urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/')

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; i++) {
            outputArray[i] = rawData.charCodeAt(1);
        }
        return outputArray;
    }


    // setting service worker url
    let swUrl = `${process.env.PUBLIC_URL}/sw.js`
    navigator.serviceWorker.register(swUrl).then((response) =>{

        // requesting permission for subscribed push notificationsn with VAPID Key
        return response.pushManager.getSubscription()
        .then(function(subscription){
            return response.pushManager.subscribe({
                userVisibleOnly:true,
                applicationServerKey:"BBsQpqOb9AOP-JB8aYdwKgPcnyLqtQs3gc5ioQT9Hi1gMFKHXIymNs6v5GrWiVTDlT2s1CEtWTdoREViiss7C_M"
            });
        });
    });

}
